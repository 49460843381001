// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$font-family-sans-serif: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';

// Colors
$red: #ED254E; // Red Crayola
$purple: #BD4089; // Red Violet Crayola
$yellow: #FBE44A; // MinionYellow
$blue: #0B4170; // Indigo Dye

// Brand Colors
$dark-blue: #013663; // Prussian Blue
$primary-blue: #013663; // Prussian Blue
$secondary-blue: $blue; // Indigo Dye
$light-blue: #69b3fe; // French Sky Blue
$very-light-blue: #AED6FF; // Baby Blue Eyes
$white-blue: #EBF5FF; // Alice Blue

$accent: $yellow; // Minion Yellow

$off-white: #fbfbfb;
// Other Colors

$gray-200:              rgba(255,255, 255, .8)!default;

$enable-cssgrid: true