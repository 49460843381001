//
// Mixins
// --------------------------------------------------


// Font smoothing

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Social buttons hover

@mixin social-btn-hover($color) {
  &:hover {
    border-color: rgba($color, .3);
    color: $color;
  }
}


// Override default Bootstrap List Groups mixin

// @mixin list-group-item-variant-custom($state, $background, $color) {
//   .list-group-item-#{$state} {
//     color: $color;
//     background-color: $background;

//     &.list-group-item-action {
//       @include hover-focus {
//         color: darken($color, 8%);
//         background-color: darken($background, 3%);
//       }
//     }
//   }
// }
