// 
// user.scss
// Use this to write your custom SCSS
//



h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: $dark-blue;
}

.text-accent {
  color: $accent !important; 
}

.text-light-muted {
  color: rgba(255, 255, 255, .7) !important;
}

.text-light-80 {
  color: rgba(255, 255, 255, .8) !important;
}

.text-module {
  color: $purple!important;
}

.text-primary-80 {
  color: hsla(208, 98%, 20%, 80%);
}

.text-book {
  color: $red!important;
}

.text-course {
  color: $light-blue!important;
}
.text-discount {
  color: #026ac5!important;
}

.bg-course {
  background-color: $light-blue!important;
}

.bg-book {
  background-color: $purple !important;
}

.bg-module {
  background-color: $red!important;
}

.bg-tile {
  background-color: $blue !important;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-menu {
  background-color: #0B4170 !important;
}

.fw-500 {
  font-weight:500 !important;
}

$ci-info: '\24D8';

.ci-info {
  &:before {
    content: $ci-info; 
  }
}


// menu
/* ul.dropdown-menu, .dropdown-menu {
  // border-top: solid 4px $accent !important;
  background-color: #232D3F!important;
}


ul.dropdown-menu li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0)!important;
  color: rgba(255, 255, 255, .9)!important;
}
ul.dropdown-menu li a:hover, ul.dropdown-menu li a:active {
  border-bottom: 1px solid rgba(255, 255, 255, 0)!important;
  color: $accent!important;
}

.mega-dropdown-column {
 // background-color: #232D3F !important;
}

.mega-dropdown-column h6, .mega-dropdown-column div {
  color: white!important;
}

.mega-dropdown-column .widget-list-link { 
  color: rgba(255, 255, 255, .8)!important;
} */

// text 








//  links

.link-delete, .link-edit {
    color: #026ac5;
}
.link-delete:hover {
    color: $red;
}
.link-edit:hover {
  color: $primary;
}

.link-tile-breadcrumb {
  color: rgba(255, 255, 255, .8);
}

.link-tile-breadcrumb:hover, .link-breadcrumb:focus {
  color: $very-light-blue;
  // text-decoration: underline!important;
}

.link-tile-icon {
  color: rgba(255,255, 255, .8);
}

.link-tile-icon:hover {
  color: $very-light-blue;
}

.link-blue {
    color:hsla(208, 98%, 39%, 1.0);
}
.link-blue:hover {
  color:hsla(208, 98%, 45%, 1.0);
}

.link-light-blue {
    color: $light-blue!important;
}

.link-muted {
    color: $dark-blue !important;
    opacity: .9 !important;
}

.link-light {
   color: rgba(255, 255, 255, .85) !important;

}

.link-light:hover {
  color:rgba(255, 255, 255, 1) !important;

}

.link-light-muted {
  color: rgba(255, 255, 255, .7) !important;
}

.link-light-muted:hover {
  color: rgba(255, 255, 255, .9) !important;
}

.link-blog-title {
  color: $primary;
}
.link-blog-title:hover {
  color: hsla(208, 82%, 33%, 1.0)!important;
}

.link-blog-meta, .blog-read-more {
  color: $primary!important;
  border-bottom: solid 1px transparent;
  transition: border-bottom .35s ease, text-decoration .35s ease;
}
.link-blog-meta:hover, .blog-read-more:hover {
  color: $primary!important;
  border-bottom: solid 1px hsla(208, 98%, 20%, .6);
}

.link-blog-meta {
  font-weight:500;
}

// buttons
.btn-placeholder {
    //color: $white;
    color: hsla(210, 55%, 29%, .9);
    //background-color: hsla(210, 55%, 29%, .2);
    background-color: hsla(210, 95%, 97%, .8);
    border-style: dashed;
    border-color: hsla(210, 55%, 29%, .4);
    transition: border-style .35s ease-in, border-color .35s ease-in, background-color .35s ease-in, color .35s ease-in;
}
.btn-placeholder:hover {
  color: hsla(210, 55%, 29%, 1);
  // color: $white;
  // background-color: hsla(210, 55%, 29%, 1);
  border-color: hsla(210, 55%, 29%, 1);
  background-color: hsla(210, 95%, 97%, 1.0);
  border-style: solid;
}
.btn-delete {
  color: $primary;
  background-color: $light;
  border: solid 1px $primary;
}
.btn-delete:hover {
  color: $white;
  background-color: $red;
  border: solid 1px $red;
}
.btn-save {
  color: hsla(0, 0%, 98%, .95);
  background-color: hsla(210, 55%, 29%, 1);
  border: solid 1px $primary;
}
.btn-save:hover {
  color: $white;
  //background-color: hsla(210, 98%, 72%, 1.0);
  background-color: hsla(210, 55%, 29%, .95);
  border: solid 1px hsla(210, 55%, 29%, 1);
}



.btn-outline-accent {
  color: $accent;
  background-color: transparent;
  border: solid 1px $accent;
  opacity: .9;
}

.btn-outline-accent:hover, .btn-outline-accent:focus {
  color: $primary !important;
  background-color: $accent;
  border: solid 1px $accent;
  opacity: 1;
}

.btn-secondary-blue-outline {
    background-color: transparent !important;
    border: solid 1px $blue !important;
    color: $blue !important;
    opacity: .8;
}

.btn-secondary-blue-outline:hover {
  background-color: $accent!important;
  border: solid 1px $accent!important;
  color: $primary!important;
  opacity: 1;
}

.btn-outline-light:hover, .btn-outline-light:focus {
  background-color: $accent!important;
  border: solid 1px $accent!important;
  color: $primary!important;
  opacity: 1;
}

// product tile popover
/*
.product-tile-popover .popover-header {
    padding: .5rem .75rem;
    background-color: white;
}
.product-tile-popover .popover-body{
  padding: .75rem .75rem;
}
.product-tile-popover ul {
    padding-inline-start: 1rem;
    margin-bottom:0;

}

.product-tile-popover li a {
    text-decoration: underline !important;
}
*/
.dot { width:12px; height:12px; margin: 10px 0px; border-radius: 50%; }  


// Grid View -- phase this out
/* .product-tile {
  background-color: $blue;
  height:240px;

  .card-header { min-height: 42px; padding-top: .375rem;}


  .card-body {
      * {transition: opacity .4s, transform .4s;}
      .reveal-on-hover { opacity:0; }
      .hide-on-hover { opacity: 1; }
      .slide-up { transform:translateY(72px);}
  }

  .card-title {max-height:96px; font-weight:500; line-height:17.5px;}
  .btn-sm {height:36px;}

  .card-body:hover, .card-body:focus, .card-body:focus-within { 
      .reveal-on-hover {opacity:1;} 
      .hide-on-hover {opacity:0;}
      .slide-up { transform:translateY(0px);}
  }

}
*/


// borders
.border-bottom-subtle {
border-bottom: solid 1px rgba(255,255,255,.1)!important;
}
.border-heavy {
   border: solid 1px hsla(210, 54%, 29%, 1.0)!important;
}
.border-bottom-heavy {
   border: 0px!important;
   border-bottom: solid 1px hsla(210, 54%, 29%, 1.0)!important;
}
.border-field {
   border: solid 1px hsla(210, 54%, 29%, .2)!important;
}


// Widget LIst

.widget-list-item {
  margin-bottom: .25rem!important;
}

.widget-list-link {
  color: $blue !important;
  opacity: .8 !important;
}

.widget-list-link:hover, .widget-list-link:focus {
  opacity: 1 !important;
}

.widget-list-caps-link {
  color: $blue;
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 500;
  opacity: .9;
}

.widget-list-caps-link:hover, .widget-list-caps-link:focus {
  color: $blue;
  opacity:1;
}

footer .widget-list-link {
  color: white !important;
}





// Products List with Grid or List View
.products-list {

  // simulates Bootstrap Card Grid
  .view {

      margin-bottom: 2rem;
      --bs-gutter-x: 1.875rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      
      .col {
          flex: 0 0 auto;
          width: 100%;
      }
  
      .card.product-tile { 
          border-color: rgba(3,54,99,.085)!important; 
          border-radius: .25rem!important;
          background-color:#F6F7F9;
          transition: background-color .45s ease-in-out;
          
          
          .card-body {
              padding: .25rem 1rem 1.25rem 1rem;
              display:flex;
              color: $primary;
              

              // Info Header
              .info-header {
                  padding-top:.5rem;
                  display:flex;
                  pointer-events: none;
                  user-select:none;
                  
                  .start-date, .end-date, .location, .type {
                      border-radius: .25rem;
                      height: 2.5rem;
                  }

                  // dates & location 
                  .start-date, .end-date, .location {
                      line-height:1.667; 
                      transition: all .45s ease-in-out;
                      font-size:.75rem;
                      font-weight:600;
                      display:none;

                      div {
                          line-height:1;
                          font-weight:normal;
                      }

                  }
                  
                  .start-date, .end-date {
                      width: 2.5rem;
                      color:white;
                      text-align:center;
                      margin-right: 12px;

                      div {
                          font-size: .875rem;
                      }

                  }

                  .start-date {
                      //background-color: $primary;  
                      background-color: rgba(3,54,99,.9);
                      border: solid 1px rgba(3,54,99,.1)!important;   
                  }

                  .end-date {
                      background-color: rgba(105, 179, 254, .95);
                      border: solid 1px rgba(3,54,99,.1)!important; 
                  }
                      
                  .location {
                  min-width:6.25rem; 
                  //background-color: #E7F1FC;
                  background-color: white;
                  border: solid 1px rgba(3,54,99,.1)!important; 
                  padding: 0 .5rem;
                  font-size:12px;
                  font-weight:700;
                  color:$primary;
                  overflow-wrap:normal;
                  margin-right:16px;
                  text-align:left;
                      
                      div {
                          font-size: .75rem;
                      }

                  }

                  .type {
                      display:flex;
                      align-items: center;
                      //justify-content: center;
                      justify-content: start;
                      //color:white;
                      margin-right: 1rem;
                      font-size: .875rem;
                      // height:34px;
                      padding: 0 .5rem;
                      //width:64px;
                      width:100px;
                      // background-color:rgba(3, 54, 99, .6);
                      background-color:white;
                      .dot {
                          margin-right: .5rem;
                      }
                      i {
                          font-size:16px;
                          margin-right: .5rem;
                      }

                  }

                  @media screen and (max-width:575.99px) {
                      .start-date, .end-date {
                          margin-right:.5rem;
                      }
                  
                      .location {
                          margin-right:0;
                      }
                  }
              
              }

              // Title
              .title {
                  font-weight: 600;
                  font-size:.9375rem;
                  margin-right: 12px;
                  line-height:1.33;
                  color: $primary;
                  flex-grow: 1;
                  padding-top:1rem;

                  .meta {
                      font-size: .875rem;
                      font-weight: 400;
                      line-height:1.5;
                      pointer-events:none;
                      user-select:none;
                  }
              }
              

              // Buttons
              .buttons {
                  display:flex;
                  justify-content:flex-end;
                  justify-self:end;
                  align-items:flex-start;
                  flex-grow:1;
                  padding-top:1rem;
                  opacity: 1;
                  transition: all .45s ease-in-out;

                  .btn {
                      transition: all .45s ease-in-out;
                      opacity:1;
                      
                      @media screen and (min-width:575px) {
                          opacity: 0;
                      }

                  }

                  .price {
                      transition: all .45s ease-in-out;
                      position: relative;
                      opacity:1;
                      margin-right: .85rem;

                      @media screen and (min-width:575px) {
                          position:absolute;
                          opacity: 1;
                          //margin-right: 0rem;
                      }
                  }
                  
                  .secondary-button {
                      background-color:rgba(1, 54, 99, 0); 
                      margin-right:12px;
                      display:none;
                  }

                  .primary-button.register {
                     display:inline;
                  }
                  .primary-button.details {
                     display:inline; 
                  }                    
              }
          } 
      }

      .card.product-tile:hover, .card.product-tile:focus, .card.product-tile:focus-within {

          background-color: #E9F1FF!important;

          .card-body {

              .buttons {
                  // opacity: 1;

                  .btn {
                      opacity: 1;
                  }

                  .price {
                      @media screen and (min-width:575px) {
                          opacity: 0;
                      }
                  }

                  .btn:hover, .btn:focus {
                      box-shadow: 0 .5rem 1rem rgba($black, .15);
                  }
              
                  .secondary-button:hover, .secondary-button:focus {
                      background-color: $primary;
                      border-color: $primary;
                      color:white;
                  }

              }
          }
      }
  
  }

  //Grid View
  .view.grid-view {
      
      // grid gutters
      --bs-gutter-y: 1rem;
      --bs-gutter-x: 1rem;

      // grid breakpoints
      .col {
          flex: 0 0 auto;
          width: 100%;
  
          @media screen and (min-width: 500px) {
          width: 50%;
          }

          @media screen and (min-width: 1200px) {
          width: 33.3333333333%;
          }
      }

      .card.product-tile {
          min-height:200px;
          
          .card-body {
              flex-direction: column;
          
              .info-header {

                  .start-date, .end-date {
                      margin-right:.5rem;
                  }
              
                  .location {
                      margin-right:0;
                  }
              }
              
              .title {
                  margin-right: 0;

                  .meta {
                      font-size: 13px;
                      padding-top:.75rem;

                      .discipline { display: block;}
                      .divider {display:none;}
                  }
              }

              .buttons {
                  align-self: flex-end;
                  align-items: flex-end;
                  justify-content: flex-end;

                      .btn {
                          //opacity:1;
                      }
              }
          }

          
      }
  }

  // List View
  .view.list-view {
          

      .col {
          margin-top:16px;

          @media screen and (min-width: 576px) {
              margin-top:0px;
          }

      }     

      .card.product-tile {
          
          margin-top: 0px;

          @media screen and (min-width: 576px) {
              border-bottom-width:0px!important;
          }

          .card-body {
              justify-content:flex-start;
              flex-grow: 1;
              flex-wrap: wrap;
          
              @media screen and (max-width:575.98px) {
                  flex-direction:column;
              }

              @media screen and (min-width: 768px) {
                  flex-wrap: nowrap !important;
              }

              .info-header {
                  padding-top:1rem;

                  @media screen and (max-width:575.98px) {
                      padding-top:.5rem;
                  }
              }

          
              .title {
                  @media screen and (max-width:575.98px) {

                      margin-right: 0;

                      .meta {
                          font-size: 13px;
                          padding-top:.5rem;

                          .discipline { display:block;}
                          .divider {display:none;}
                      }

                  }
              }

          }

      }

      .col:last-child .card.product-tile {
          border-bottom-width:1px!important;
      }
      
      
  }


}

.products-list.scheduled {

  .view {
      .card.product-tile {
          .card-body {
              .info-header {
                  .start-date, .end-date, .location {
                      display:block;
                  }
                  .type {
                      display:none;
                  }
              }

              .buttons {

                  .secondary-button {
                      display:block;
                  }

                  .primary-button.register {
                      display:inline;
                  }
                  .primary-button.details {
                      display:none;
                  }
              }
          }
      }
  }
}

.coursedates {

  .date, .date-label {
      font-size: .75rem;
      text-align: center;
      margin-right: .5rem;
      width: 2.5rem;
  }

  .date-label {
      margin-bottom:0;
      color: #013663;
  }

  // dates & location 
  .date {
      line-height:1.667; 
      transition: all .45s ease-in-out;
      font-weight:600;
      color: white;
      border-radius: .25rem; 
      height: 2.5rem;
      text-align:center;
      background-color: rgba(3,54,99,.9);
      border: solid 1px rgba(3,54,99,.1)!important;  

      div {
          line-height:1;
          font-weight:normal;
          font-size: .875rem;
      }

  }
  // last date tile in week row
  div:nth-child(7).date {
  margin-right:0px;
}
}






/* Cart */
.card .placeholder {
  //transition: background-color .25s ease-in, color .25s ease-in;
  //color:white;
}
.card .placeholder:hover {
  //background-color: rgba(3,54,99,1)!important;
  //color:white;
}


/* Text Utilities */

.text-muted {
color: $gray-500 !important;
}

.text-gray-200 {
  color: $gray-200 !important;
}
/* Autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
{
  background-color: white!important;
  color: $primary!important;
//border: 1px solid red;
-webkit-text-fill-color:$primary!important;
-webkit-box-shadow: 0 0 0px 1000px #fff inset;
transition: background-color 5000s ease-in-out 0s;
}


/* credit card form */
#payment-card {
  overflow:hidden;
}

#payment-card .card-header {
  font-size: 1.0625rem;
  line-height:1.2;
  font-weight:500;
  color:hsla(211, 54%, 29%, 1);
  padding:0;
  display:flex;
  justify-content:space-between;
  overflow:hidden;
  margin-bottom:0px;
}

#payment-card .card-header .nav-item {
  display:inline-block;
  width:50%;
  flex-grow:1;
  display:flex;
  justify-content:center;
}

#payment-card .card-header .card-heading{
  display:inline-block;
  flex-grow:1;
  padding:10px 20px;
}
#payment-card .card-header .nav-item .nav-link {
  color:hsla(211, 54%, 29%, .8);
  padding:0 0;
  line-height:40px;
  
  background-color:hsla(211, 54%, 29%, 0);
  width:100%;
  text-align:center;
  //transition: background-color .35s ease-in, color .35s ease-in;
}
#payment-card .card-header .nav-item:first-child .nav-link {
  //border-top-left-radius: 0.4375rem;
}

#payment-card .card-header .nav-item .nav-link::before {
  display:none;
}

#payment-card .card-header .nav-item .nav-link.active {
  color:$white!important;
  background-color:$primary;
}
/* hide border for first item in cart pane */
#modal-cart-pane .card-body .cart-tile:first-child div, #modal-payment-pane .card-body .cart-tile:first-child div, #modal-thank-you-pane .card-body .cart-tile:first-child div  {
  border:none!important;
}

#modal-cart-pane .card-footer,  #modal-payment-pane .card-footer, #modal-thank-you-pane .card-footer {
  border-top: solid 1px $primary;

}

#modal-cart-pane .card-footer,  #modal-payment-pane .card-footer .subtotal, #modal-thank-you-pane .card-footer .subtotal{
  font-weight:400!important;
}

#modal-cart-pane .card-footer,  #modal-payment-pane .card-footer p:last-of-type, #modal-thank-you-pane .card-footer p:last-of-type{
  margin-top:12px!important;
}

// about

.row .alliance-logo-card {
  background-color: white;
  opacity:.95;
  transition:opacity .35s ease-in-out;
}

.row:hover .alliance-logo-card {
  opacity:.6;
  transition:opacity .35s ease-in-out;
 }

.row .alliance-logo-card:hover {
  opacity:1
}

.solution-tile {
  background-color: hsla(210, 95%, 97%, 1.0)!important;
  transition:background-color .35s ease-in-out, box-shadow .3s ease-in-out;

}

.solution-tile:hover {
  background-color: rgba(255, 255, 255, .8)!important;
  box-shadow: 0 1rem 3rem rgba($black, .175)!important;
}


// shadow hover on linked tile

a.card.card-hover-shadow {
  transition: box-shadow .35s ease-in-out;
}

a.card.card-hover-shadow:hover {
  box-shadow: 0 1rem 3rem rgba($black, .175)!important;
}


a.instructor-link:hover {
text-decoration:underline!important;
color: $secondary-blue
}


// Contact Page
.contact-card li {
  transition-duration: .35s;
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, background-color, color;
 
}

.contact-card li:hover {
  box-shadow: 0 1rem 3rem rgba($black, .175)!important;
  background-color: $primary!important;
  color: $light!important;

  a.text-primary { color: $light!important;  } 
}

// CSS Play Button

.video-thumb-container {
      cursor:pointer;

  .css-play-container {
      box-shadow: 0 .5rem 1rem rgba($dark, .85);
      opacity:.85;
      transition: .25s all ease; 
      background-color: #031F40;
      padding:.85em 1em .85em 1.2em;
      
      .css-play {
          
          font-size: 6px; 
          height: 4.625em;
          border-color: transparent transparent transparent #fff; 
          transition: 100ms all ease; 
          cursor: pointer; 
          border-style: solid; 
          border-width: 2.3125em 0 2.3125em 3.75em;
      }
  }
}
.video-thumb-container:hover {
  .css-play-container {
      opacity:1;
      box-shadow: 0 1rem 3rem rgba($black, .875);
      background-color: $info;
      
      .css-play {
          
      }
  }
}


// Progression Maps


.map {


 // both foreground and background
 .map-container {
     overflow-x: scroll;
     height:auto;
     overflow-y:hidden;
     max-width: 100vw;
    }

 .progression-map.container-fluid {
     min-width: 1200px;
     padding:0!important;

     .headings-row {
        height:auto!important;

        .col-1, .bg-map-primary, .map-heading {
          height: calc(1.92rem + 12px);
        }
      
        
    }

    .subheadings-row {
      height:auto!important;

      .col-1, .subcategory{
        height: calc(1.92rem + 12px);
      }
  }

    // adjusting column-width
    .col-1:not(col-1:first-child) { width: calc((100% - 30px)  / (var(--columns) - 1));}
    .col-2 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 2));}
    .col-3 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 3));}
    .col-4 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 4));}
    .col-5 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 5));}
    .col-6 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 6));}
    .col-7 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 7));}
    .col-8 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 8));}
    .col-9 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 9));}
    .col-10 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 10));}
    .col-11 { width: calc((100% - 30px)  / ((var(--columns) - 1) / 11));}


    // first column - level labels
    .row .col-1:first-child {
      transform: rotate(90deg) !important;
      padding-left: 10px !important;
      width: 30px !important;
      height: 20px !important;
      text-overflow: nowrap !important;
      overflow: visible !important;
    }

   

    // blank columns at end
    .row .map-headings:not(.discipline-heading):not(:first-child) {
      display: none;
    }
 }   



 // map foreground only
 div:not(.map-background-layer) .progression-map {
    font-size: 0.8rem;
    line-height: 1.2;
    color: #031F40;
  
    .headings-row {
      font-weight: 500;
      color:$light;

      .map-headings {
        padding:6px;
        height: calc(1.92rem + 12px);
        
        div {
          height: 1.92rem;
          display: -webkit-box;
          overflow:hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

              
      }
    
    .first-heading {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }

    .last-heading {
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }

     .discipline-heading {
        transition:background-color .25s ease;
     } 
     .discipline-heading:hover {
       background-color: hsla(204, 100%, 40%, 1.0)!important;
       cursor:pointer;
     }

    }

    .subheadings-row {
  

      .col-1 {
        color: $light!important;
        height: calc(1.92rem + 12px);



        .subcategory-heading {
          height: 1.92rem;
          display: -webkit-box;
          overflow:hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;


        }
      }
      
      .col-1[data-subheading*="_"] {display:none;}

      .first-subcategory {
        border-bottom-left-radius: .25rem;
      }

      .last-subcategory {
        border-bottom-right-radius: .25rem;
      }

      .subcategory {
        transition:background-color .25s ease;
     } 
     .subcategory:hover {
       background-color: hsla(204, 100%, 40%, 1.0)!important;
       cursor:pointer;
     }

    }
    
  
    .map-level-heading {
      color: #031f40;
      font-size: 0.8rem;
      font-weight: 500;
      padding-bottom:16px!important;
    }

    
    
    .map-course-row {
      height: 3.875rem;
      margin-top:0;
      
       .col, .map-level-heading, .map-course-col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
          height: 3.875rem;
      
        .card {
        height: 3.375rem;
        cursor:pointer;
            
          .card-body {
              transition: background-color .25s ease, box-shadow .25s ease;
              box-shadow: 0 .5rem 1rem rgba($black, .15);

              .card-heading {
              word-break: normal;
              display: -webkit-box;
              height: 2.88rem;
              overflow:hidden;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              
              }

        

          }

          

          
          
          .card-body:hover {
            background-color: #031F40;
            background-color: hsla(204, 100%, 40%, 1.0);
            box-shadow: 0 1rem 3rem rgba($black, .175);
          }
        }
        
      
      }
    }
  
    .map-course-row.first-row  {
      height: 4.62rem;
      margin-top: 2rem;
      
      .col, .map-level-heading, .map-course-col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        padding-top:1rem!important;
        height: 4.62rem;
        
        .card {
        height: 3.375rem;
        }
        
      }
      
  
    }
    
    .map-course-row:last-child {
      height: 4.62rem;
       
      .col, .map-level-heading, .map-course-col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        padding-bottom:1rem!important;
        height: 4.62rem;
        
        .card {
        height: 3.375rem;
        }
      }
    }
  
    .map-course-row:only-child {
      height: 5.62rem;
      
      .col, .map-level-heading, .map-course-col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        padding-top:1rem!important;
        padding-bottom:1rem!important;
        height: 5.62rem;
        
        .card {
        height: 3.375rem;
        
        }
        
      }
    } 
  }
  
  // map background only
  .map-background-layer {
    .progression-map {
      .headings-row {

   

         .bg-map-default, .bg-map-primary {
             background-color: hsla(213, 91%, 13%, 1.0)!important;
             border-color: white!important;
         }

         .first-heading {
             border-top-left-radius: .25rem;
             border-bottom-left-radius: .25rem;
         }

         .last-heading {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }

        // hiding extra blank heading columns in background-layer
        .col-1:not(:first-child):not(.bg-map-primary):not(.bg-map-default) {
          display:none!important;
        }
      }
      .subheadings-row {
          .bg-map-highlight {
              background-color: hsla(213, 91%, 13%, 1.0)!important;
              
              border-top-width:.5px!important;
              border-top-style: solid!important;
              border-color: hsla(99, 100%, 100%, .5)!important;
              border-bottom-color: white!important;
              //border-top-color:hsla(99, 100%, 100%, .2)!important;
              border-left-width: 0px!important;
              border-right-width: .5px!important;
          }

          .first-subcategory {
              border-bottom-left-radius: .25rem;
              border-left-color: white!important;
              border-left-width: 1px!important;
          }

          .last-subcategory  {
            border-bottom-right-radius: .25rem;
            border-right-color: white!important;
            border-right-width: 1px!important;
        }
      }  
      .map-level {


        .map-course-row:first-child {
          .first-course-column { 
            border-top-left-radius: .25rem;
  
          }
          .last-course-column {
            border-top-right-radius: .25rem;
   
          }
        }
        .map-course-row:last-child {
          .first-course-column { 
            border-bottom-left-radius: .25rem;
  
          }
           .last-course-column {
            border-bottom-right-radius: .25rem;
  
          }
        }
    
      }
    }
  }
  

  
 

  .bg-specialized, .bg-intermediate, .bg-foundation, .bg-basic {
    background-color: hsla(208, 98%, 20%, 1);
  }

  .bg-map-default {
    background-color: hsla(210, 14%, 92%, 1);
  }


  .bg-map-highlight {
    background-color: hsla(216, 98%, 53%, 0.2);
  }
  
  .border-transparent {
    border-color: rgba(255, 255, 255, .0)!important;
  }


}


// Checkout --> Book Placeholder

.Book-cover {
  .Book-title {
    max-height: calc(4 * 1.5 * .875rem);
    overflow:hidden;
    display: -webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:4;

  }

  div:last-child {
    max-height: calc(2 * 1 * .75rem);
    overflow:hidden;
    display: -webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }

}

// blog

.blog-article {
  h1 {
    margin-bottom:2rem;
    font-size:2rem;
  }
  h2 {
    margin-top: 2.5rem;
    font-size:1.65rem;
  }

  h3 {
    margin-top: 2rem;
    font-size: 1.5rem;
  }
  
  ol, ul {
    padding-top: .5rem;
    padding-left:1rem;
    margin-bottom:1.5rem;

    h3, h4, h5 {
      margin-top:.5rem;
      font-weight:500!important;
      font-size:1.35rem;
    }
  
    li {
      list-style-position: outside;
      padding-left:.5rem;
      margin-bottom:1rem;

      ol, ul {


        li { 
          margin-bottom: .5rem;
        }
      }
    }

  }


  figure {
    margin: 2rem 0;
  }

  .blog-picture-inline {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  
}

// search - nav dropdown
.nav-search {
  border-radius: .3125rem;

  input {
    z-index:2;
    background-color:white;
    padding-left:1rem!important;
    font-weight:700;
    font-size:1.15rem;
  }

  input::placeholder {
    padding-left:1.5rem!important;
    font-weight:400;
    font-size:1rem;
  }


  .results {
    border-radius: .3125rem!important;
    overflow:hidden;
    opacity:0;
    pointer-events:none;
    transition: opacity .35s ease;
    box-shadow:0 1rem 3rem rgba($black, .175);
    color: $primary;
    background-color: hsla(210, 97%, 97%, 1.0);
    background-color:white;
    z-index: 1;
    padding-top:2rem;
    padding-bottom:1rem;

    li {
      margin-bottom:0px;

      span:first-child {
        display:inline-block;
        min-width: 62px;
        text-align: left;
        //text-transform:uppercase;
        //font-size:.75rem;
        //font-weight:300;
        margin-right:.75rem;
        border-right:solid .5px hsla(208, 82%, 24%, .4);
        border-top-color:transparent;
        border-bottom-color:transparent;
      }
    }

    li.list-heading {
      margin-top:.5rem;
      padding: .25rem 1rem;
    }

    li.list-heading, li.cta a {
      font-size:.8rem;
      color: hsla(208, 98%, 20%, .6);
    }


    li.hr {
      margin: .5rem 0;
      border-bottom:solid .5px hsla(208, 82%, 24%, .3);
      display:none;
    }

    li a {
      display:inline-block;
      padding: .25rem 1rem;
      transition: color .35s ease, background-color .35s ease;
      width:100%
    }

    li a:last-child {
      padding-bottom:.5rem;
    }

    li.cta a {
      // border-top: solid 1px hsla(208, 82%, 24%, .3);
      padding: .5rem 1rem;
      margin-bottom: .5rem;
      margin-top: .25rem;
    }

    li a:hover {
      color:white;
      background-color:$dark;
    }
  }

  input:focus, input:-webkit-autofill, input:not(:placeholder-shown) {
    background-color:transparent!important;
    border: solid 0px transparent;
    box-shadow:none;
  }

  input:focus::placeholder, input:-webkit-autofill::placeholder, input:not(:placeholder-shown)::placeholder {
    opacity:0;
  } 

  // hide icon
  input:focus + i, input:-webkit-autofill + i, input:not(:placeholder-shown) + i {
    display:none;
  }

  // results active
  input:focus ~ .results, input:-webkit-autofill ~ .results, input:not(:placeholder-shown) ~ .results {
    opacity:1;
    pointer-events:auto;
  }

}

// search - results page
.results {


  .result {
    a {
      h3 {
        span {
          border-bottom: solid 1px transparent;
          transition: border .35s ease;
          
        }
      }
      p {
        span.term { 
          font-weight:700!important;
        } 
      }
    }
    a:hover {
      h3 {
        span {
          border-bottom: solid 1px hsla(213, 91%, 13%, .8);
        }
      }
    }
  }
  .result:last-child hr {
    display:none;
  }
}